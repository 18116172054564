<template>
  <div class="search-bar-wrap">
    <div v-if="$store.state.setting.advertises">
      <div class="adv" v-if="showflag">
        <a :href="$store.state.setting.advertises.link" target="_blank">
          <img @click="viewsAds($store.state.setting.advertises.id)" alt
               :src="$store.state.setting.advertises.imgurl"
               style="width: 100%;height: 70px;"/>
        </a>
        <i @click="closeAdv()" class="el-icon-circle-close delete"></i>
      </div>
    </div>
    <div class="search-bar">
      <div class="sctp-container sctp-flex sctp-flex-aic">
        <div class="sctp-flex">
          <router-link to="/">
            <div class="flex flex-center">
              <div class="sctp-mar-r10 flex flex-center">
                <el-image
                  src="/images/logo/sctplog24.png"
                  style="width: 24px;height: 24px;"
                ></el-image>
              </div>
              <div class="logo_text">默蓝网</div>
            </div>
          </router-link>
          <div class="logo_info fz-14">
            <div>让您的知识技能可以变现的平台</div>
            <div class="sctp-color-main sctp-flex sctp-flex-sb">
              <span>源码</span>
              <span>设计</span>
              <span>开发</span>
              <span>技术</span>
              <span>服务</span>
              <span>文档</span>
            </div>
          </div>
        </div>
        <div class="center sctp-flex-item-1">
          <el-input placeholder="请输入内容" v-model="searchForm.searchcontent"
                    class="input-with-search">
            <el-select slot="prepend" :popper-append-to-body="false"
                       v-model="searchForm.searchtype"
                       class="search-select">
              <template v-for="type in searchtypeList">
                <el-option :key="type.name" :label="type.name" :value="type.value"></el-option>
              </template>
            </el-select>
            <el-button @click="searchBtn" slot="append" icon="el-icon-search"></el-button>
          </el-input>
        </div>
        <div class="right">
          <router-link to="/submit-requirement">
            <div class="send_demand">发布我的需求</div>
          </router-link>
          <router-link to="/shop/release-production">
            <div class="send_source">发布我的原创</div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {common} from "../../apis";

export default {
  name: 'HomeSearchBar',
  props: [],
  data() {
    return {
      searchForm: {
        searchtype: 1,
        searchcontent: null
      },
      showflag: false
    }
  },
  beforeMount() {
    let {search, type = 1} = this.getQuery();
    if (search) {
      this.searchForm.searchcontent = search;
      this.searchForm.searchtype = type;
    }
    if (!this.$store.state.setting.advertises) {
      this.showflag = true;
    }
  },
  methods: {
    closeAdv() {
      this.showflag = false;
    }, viewsAds(id) {
      common.AdsviewHistory({
        userId: (this.user && this.user.userId) || null,
        id: id,
      })
    },
    searchBtn() {
      var type = this.searchForm.searchtype;
      var search = this.searchForm.searchcontent;
      let path;
      switch (type) {
        case 1:
          path = '/center/requirement';
          break;
        case 2:
          path = '/center/sourcecode';
          break;
        case 3:
          path = '/center/design';
          break;
        case 4:
          path = '/center/techservice';
          break;
        case 5:
          path = '/center/techdocs';
          break;
        case 6:
          path = '/center/parttime';
          break;
      }
      this.goPage({
        path: path,
        query: {
          search: search,
          type: type,
        },
      });
    }
  },
  computed: {
    searchtypeList() {
      return [
        {name: '需求', value: 1},
        {name: '源码', value: 2},
        {name: '设计', value: 3},
        {name: '服务', value: 4},
        {name: '文档', value: 5},
        {name: '兼职', value: 6}
      ]
    }
  }
}
</script>

<style scoped lang="scss">
.search-bar-wrap {
  background-color: #fff;

  .search-bar {
    height: 80px;
    padding: 15px 0;
    display: flex;
    align-items: center;

    .logo_text {
      margin-right: 10px;
      padding-right: 15px;
      border-right: 5px solid #F90;
      color: #F90;
      font-weight: 700;
      font-size: 24px;
      cursor: pointer;
    }

    .logo_info {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .center {
      padding: 0 20px;

      ::v-deep {

        .input-with-search {
          border: 2px solid $main-color;
        }

        .el-input-group__append, .el-input-group__prepend {
          border: none;
          background-color: #fff;
        }

        .el-input__inner {
          border: none;
        }

        .el-input-group__append {
          background-color: $main-color;
          color: white;
        }

        .el-input-group__prepend {
          border-right: 1px solid #eeeeee;

          .search-select {
            width: 80px;
          }
        }
      }
    }

    .right {
      display: flex;
      font-size: 18px;
      cursor: pointer;

      a {
        color: #fff !important;
      }

      .send_demand, .send_source {
        height: 40px;
        line-height: 40px;
        padding: 0 25px;
      }

      .send_demand {
        background-color: #f90;
      }

      .send_source {
        background-color: #f60;
      }
    }
  }

  .adv {
    width: 1190px;
    padding-top: 10px;
    margin: 0 auto;
    position: relative;
  }

  .delete {
    font-size: 26px;
    position: absolute;
    right: 50px;
    z-index: 100;
    line-height: 70px;
    cursor: pointer;
  }

}
</style>
